





























































import Vue from 'vue'

export default Vue.extend({
  data() {
    return {
      loading: false,
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
        orderBy: {},
      },
      entities: [],
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      const res = await this.$http.request({
        url: 'service/codes',
        method: 'get',
        params: this.paginate,
      })
      this.entities = res.data.data
      this.loading = false
      this.paginate.total = res.data.meta.total
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.fetch()
    },
    handleSizeChange(size: number) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page: number) {
      this.paginate.page = page
      this.fetch()
    },
    handleSortChange({ prop, order }: { prop: any; order: any }) {
      if (order) {
        this.paginate.orderBy = {
          prop,
          order: order.replace('ending', ''),
        }
      } else {
        this.paginate.orderBy = {}
      }
      this.fetch()
    },
    handleRemove(row: any) {
      this.$confirm(`此操作将永久删除"${row.code}", 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        await this.$http.delete(`service/codes/${row.id}`)
        this.$message.success('删除成功')
        this.fetch()
      })
    },
  },
})
